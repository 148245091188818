import React from 'react';
import styled from 'styled-components';

import { ReactComponent as OpenRawIcon } from 'assets/icons/external-link.svg';

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  svg {
    fill: var(--white-white);
    width: 10px;
    height: 10px;
  }
`;

const OpenRawViewButton = () => (
  <Link href="/lista-wiadomosci?raw" target="_blank">
    <OpenRawIcon />
  </Link>
);

export default OpenRawViewButton;
