import styled from 'styled-components';

const Root = styled.div`
  border-radius: 57px;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  align-items: center;
  gap: 10px;
  bottom: 20px;
  left: ${({ leftAlign }) => (leftAlign ? '50px' : '50%')};
  transform: ${({ leftAlign }) => (leftAlign ? 'translateX(0)' : 'translateX(-50%)')};
  backdrop-filter: blur(3px);
  padding: 10px;
  z-index: 1000;
`;

export default Root;
