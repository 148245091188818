import { LiveChatWidget } from '@livechat/widget-react';
import React from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';

import { queryParamExists } from 'utils';

import { AlertContainer } from 'components/Alert';
import Notifications from 'components/Notifications';
import BuildHash from 'components/BuildHash';

import AlertsControlWidget from './components/AlertsControlWidget';
import CookiesInfo from './components/CookiesInfo';
import AdditionalReminder from './components/AdditionalReminder';
import SendTestTipModal from './components/SendTestTipModal';
import VerifiedUserWizard from './components/VerifiedUserWizard';

import UserPanel from './pages/UserPanel';
import Configurators from './pages/Configurators';
import Payments from './pages/Payments';
import TipsPageSettings from './pages/TipsPageSettings';
import TipsList from './pages/TipsList';
import NotFound from './pages/NotFound';
import Goals from './pages/Goals';

import MenuTop from './components/MenuTop';
import GlobalStyle from './styles/Global';
import GlobalStyleDark from './styles/GlobalDark';
import AnimationsStyle from './styles/Animations';
import UniversalStyle from './styles/Universal';

import 'normalize.css';
import 'rc-slider/assets/index.css';
import 'react-contexify/dist/ReactContexify.min.css';
import 'styles/components/contexify.scss';

const ContentWrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 10px 15px;
`;

const history = createBrowserHistory();

const raw = queryParamExists('raw');

const App = ({ currentTheme }) => (
  <Router history={history}>
    <React.Fragment>
      <Switch>
        <Route exact path="/" />
        <Route path="/konfigurator">
          <GlobalStyleDark />
        </Route>
        <Route path="/:slug">
          {(currentTheme === 'default' && <GlobalStyle />) || <GlobalStyleDark />}
        </Route>
      </Switch>

      <Switch>
        <Route exact path="/" />
        <Route path="/:slug">
          <React.Fragment>
            <AnimationsStyle />
            <UniversalStyle />
          </React.Fragment>
        </Route>
      </Switch>

      {!raw && (
        <Switch>
          <Route exact path="/" />
          <Route path="/404" />
          <Route path="/:slug" component={MenuTop} />
        </Switch>
      )}

      {[
        '/panel-uzytkownika',
        '/lista-wiadomosci',
        '/wyplaty',
        '/cele',
        '/ustawienia-strony-zamowien',
      ].map(route => (
        <Route path={route} key={route} component={AdditionalReminder} />
      ))}

      <ContentWrapper>
        <Switch>
          <Redirect exact from="/" to="/panel-uzytkownika" />
          <Route path="/panel-uzytkownika">
            <UserPanel />
          </Route>
          <Route path="/lista-wiadomosci">
            <TipsList />
          </Route>
          <Route path="/wyplaty" component={Payments} />

          <Redirect exact from="/konfigurator" to="/konfigurator/powiadomienie-o-wiadomosci" />
          <Route path="/konfigurator/:configurator" component={Configurators} />
          <Route path="/cele">
            <Goals />
          </Route>

          <Route path="/ustawienia-strony-zamowien">
            <TipsPageSettings />
          </Route>

          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </ContentWrapper>

      <Notifications />
      <AlertsControlWidget />

      <VerifiedUserWizard />
      <SendTestTipModal />
      <AlertContainer />
      <CookiesInfo />
      {!raw && <LiveChatWidget license="15525981" />}
      <BuildHash />
    </React.Fragment>
  </Router>
);

App.propTypes = {
  currentTheme: PropTypes.string.isRequired,
};

export default App;
