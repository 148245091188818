import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as MaterialSpinner } from 'assets/icons/material-spinner.svg';

import Root from './components/Root';

const Button = ({ children, loading, noWrapper, icon, iconSuffix, ...props }) => (
  <Root type="button" {...props} loading={loading ? `true` : ''} withIcon={icon || iconSuffix}>
    {noWrapper && !(icon || iconSuffix) ? (
      children
    ) : (
      <div>
        {icon && icon}
        {children}
        {iconSuffix && <div className="suffixIcon">{iconSuffix}</div>}
      </div>
    )}
    {loading && <MaterialSpinner />}
  </Root>
);

Button.propTypes = {
  children: PropTypes.node,
  noWrapper: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  iconSuffix: PropTypes.node,
};

Button.defaultProps = {
  children: null,
  noWrapper: false,
  loading: false,
  icon: null,
  iconSuffix: null,
};

export default Button;
