import defaultBackground1 from 'assets/images/configurators-backgrounds/1.png';
import defaultBackground2 from 'assets/images/configurators-backgrounds/2.png';
import defaultBackground3 from 'assets/images/configurators-backgrounds/3.png';
import defaultBackground4 from 'assets/images/configurators-backgrounds/4.png';

import GOOGLE_POLISH_FEMALE from 'assets/voices/GOOGLE_POLISH_FEMALE.mp3';
import GOOGLE_POLISH_MALE from 'assets/voices/GOOGLE_POLISH_MALE.mp3';

import GOOGLE_POLISH_MALE2 from 'assets/voices/GOOGLE_POLISH_MALE2.mp3';
import GOOGLE_POLISH_FEMALE2 from 'assets/voices/GOOGLE_POLISH_FEMALE2.mp3';
import GOOGLE_POLISH_FEMALE3 from 'assets/voices/GOOGLE_POLISH_FEMALE3.mp3';
import AMAZON_POLISH_EWA from 'assets/voices/AMAZON_POLISH_EWA.mp3';
import AMAZON_POLISH_MAJA from 'assets/voices/AMAZON_POLISH_MAJA.mp3';
import AMAZON_POLISH_JACEK from 'assets/voices/AMAZON_POLISH_JACEK.mp3';
import AMAZON_POLISH_JAN from 'assets/voices/AMAZON_POLISH_JAN.mp3';

import defaultTemplates from './data/defaultTemplates';

export default {
  mediaManager: {
    isFetching: false,
    isFetched: false,
    showManager: false,
    usage: 0,
    items: [],
  },

  // Obiekty konfiguracyjne dla konfiguratorów
  configuratorsConfigs: {
    isFetching: false,
    isFetched: false,
    isUpdating: false,

    globals: {
      profanity_filter: { enabled: true },
      forbidden_words: {
        enabled: false,
        words: [],
      },
    },

    configurations: {},
    displaySettingsDialog: {
      open: false,
      currentView: 'templates',
    },
  },

  // Tła w polu edycyjnym konfiguratorów
  configuratorsBackground: {
    defaults: [
      { id: 'DEFAULT_1', background: defaultBackground1 },
      { id: 'DEFAULT_2', background: defaultBackground2 },
      { id: 'DEFAULT_3', background: defaultBackground3 },
      { id: 'DEFAULT_4', background: defaultBackground4 },
    ],

    active: defaultBackground1,
  },

  // Configurators templates
  templates: {
    isFetching: false,
    isFetched: false,
    isCreating: false,

    active: {
      TIP_ALERT: 'DEFAULT_TIP_ALERT_1',
      LARGEST_DONATES: 'DEFAULT_LARGEST_DONATES_1',
      LATEST_DONATES: 'DEFAULT_LATEST_DONATES_1',
      TIPS_GOAL: 'DEFAULT_TIPS_GOAL_1',
      COUNTER_TO_END_LIVE: 'DEFAULT_COUNTER_TO_END_LIVE_1',
      GOAL_VOTING: 'DEFAULT_GOAL_VOTING_1',
    },

    defaults: [...defaultTemplates],
    created: [],
    currentTemplateId: 0,
  },

  announcements: {
    failurePatching: false,
    failureFetching: false,
    isPatching: false,
    isFetching: false,
    isFetched: false,
    forceMoreInfoModal: false,

    items: [],
  },

  extraAnnouncements: {
    failurePatching: false,
    failureFetching: false,
    isPatching: false,
    isFetching: false,
    isFetched: false,

    items: [],
  },

  commissions: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    tresholds: [],
  },

  paymentMethodsConfiguration: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    paymentMethods: {
      cashbill: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
      cashbill_blik: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
      cashbill_credit_card: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
      gt_psc: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
      psc: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
      justpay: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
      justpay_classic: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
      justpay_full: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
      paypal: {
        state: 'hide',
        is_for_verified: false,
        tooltip_text: '',
        on_hover_text: '',
        disabled_label: '',
      },
    },
  },

  withdrawalMethodsConfiguration: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    config: {
      bank_express: {
        enabled: false,
      },
      bank_standard: {
        enabled: false,
      },
      paypal: {
        enabled: false,
      },
      paypal_express: {
        enabled: false,
      },
    },
  },

  userData: {
    failureFetching: false,
    isPatching: false,
    isFetching: false,
    isFetched: false,

    patchingFragment: null,

    showVerifyWithBankTransferModal: false,

    profile: {},
    info: {},
    topDonators: [],
    accounts: [],
    paymentMethods: {
      paypal: {
        human_method_name: 'PayPal',
        minimal_amount: 200,
        minimal_limit: 100,
        enabled: false,
      },
      cashbill: {
        human_method_name: 'Przelew',
        minimal_amount: 100,
        minimal_limit: 100,
        enabled: true,
      },
      cashbill_blik: {
        human_method_name: 'BLIK',
        minimal_amount: 100,
        minimal_limit: 100,
        enabled: true,
      },
      cashbill_credit_card: {
        human_method_name: 'Karty płatnicze',
        minimal_amount: 100,
        minimal_limit: 100,
        enabled: true,
      },
      gt_psc: {
        human_method_name: 'PaySafeCard',
        minimal_amount: 100,
        minimal_limit: 100,
        enabled: true,
      },
      psc: {
        human_method_name: 'paysafecard',
        minimal_amount: 100,
        minimal_limit: 100,
        enabled: true,
      },
      justpay: {
        human_method_name: 'SMS Plus',
        minimal_amount: 100,
        minimal_limit: 100,
        enabled: true,
      },
      justpay_classic: {
        human_method_name: 'SMS',
        minimal_amount: 100,
        minimal_limit: 100,
        enabled: true,
      },
      justpay_full: {
        human_method_name: 'SMS FULL',
        minimal_amount: 100,
        minimal_limit: 100,
        enabled: true,
      },
    },
  },

  // Głosy Text-to-Speech
  speechSynthesizerVoices: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    available: [
      {
        id: 'DISABLED',
        title: 'Brak',
      },
      {
        id: 'GOOGLE_POLISH_FEMALE',
        title: 'Google Damski',
      },
      {
        id: 'GOOGLE_POLISH_MALE',
        title: 'Google Męski',
      },
      {
        id: 'GOOGLE_POLISH_FEMALE2',
        title: 'Google Maria',
      },
      {
        id: 'GOOGLE_POLISH_FEMALE3',
        title: 'Google Zosia',
      },
      {
        id: 'GOOGLE_POLISH_MALE2',
        title: 'Google Patryk',
      },
      {
        id: 'AMAZON_POLISH_EWA',
        title: 'Głos Ewa',
      },
      {
        id: 'AMAZON_POLISH_MAJA',
        title: 'Głos Maja',
      },
      {
        id: 'AMAZON_POLISH_JACEK',
        title: 'Głos Jacek',
      },
      {
        id: 'AMAZON_POLISH_JAN',
        title: 'Głos Jan',
      },
    ],

    test: {
      GOOGLE_POLISH_MALE,
      GOOGLE_POLISH_FEMALE,
      GOOGLE_POLISH_FEMALE2,
      GOOGLE_POLISH_FEMALE3,
      GOOGLE_POLISH_MALE2,
      AMAZON_POLISH_JAN,
      AMAZON_POLISH_JACEK,
      AMAZON_POLISH_EWA,
      AMAZON_POLISH_MAJA,
    },
  },

  // Statystyki
  statistics: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    data: {
      points: 0,
      tips: {
        count: 0,
        messages_length: 0,
        count_percentage: 0,
        messages_length_percentage: 0,
      },
      income: {
        last7: 0,
        last28: 0,
        current_month: 0,
        total: 0,
        last7_percent_change: 0,
        current_month_percent_change: 0,
      },
    },
  },

  // Wypłaty
  withdrawals: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    items: [],
  },

  // Ostatnie wypłaty
  latestWithdrawals: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    items: [],
  },

  // Raporty SMS
  reports: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    items: [],
  },

  // Faktury
  invoices: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    items: [],
  },

  // Napiwki użytkownika
  tips: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,
    isResending: false,
    failureResending: false,
    isDeleting: false,
    failureDeleting: false,

    tips: [],
  },

  // Fonty
  webfonts: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,

    items: [
      { family: 'Google Sans', local: true },
      { family: 'Burbank Big', local: true },
      { family: 'Bebas Neue', local: true },
    ],
  },

  // Cele
  goals: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,
    isPatching: false,

    items: [],
  },

  // Moderatorzy
  moderators: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,
    isPatching: false,

    items: [],
  },

  // Tip list goals
  tiplist: {
    showGoals: false,
    playAudio: false,
  },

  // Napiwki użytkownika moderacja
  tipsModeration: {
    failureFetching: false,
    isFetching: false,
    isFetched: false,
    isApproving: false,
    failureApproving: false,
    isRejecting: false,
    isRestoring: false,
    failureRejecting: false,
    forceModerationBasketModal: false,

    tipsModeration: [],
    tipsModerationBasket: [],
    currentModerator: [],
  },

  goalsVoting: {
    isFetching: false,
    isFetched: false,
    isPatching: false,

    config: {
      finishDate: null,
      goals: [],
    },
  },
};
