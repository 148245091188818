import React from 'react';
import styled from 'styled-components';

import Container from './styled/Container';

const Wrapper = styled(Container)`
  padding-top: 0;
  padding-right: 25px;
  font-size: 9px;
  color: var(--text-color-text-editor-notactive);
`;

const BuildHash = () => <Wrapper>{`Build: ${process.env.REACT_APP_BUILD_HASH}`}</Wrapper>;

export default BuildHash;
