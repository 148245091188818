import React from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { createBrowserHistory } from 'history';

import { queryParamExists } from 'utils';

import ModeratorPage from './pages/ModeratorPage';
import ModeratorBasketPage from './pages/ModeratorBasketPage';
import ModeratorTop from './components/ModeratorTop';
import GlobalStyle from './styles/Global';
import GlobalStyleDark from './styles/GlobalDark';
import AnimationsStyle from './styles/Animations';
import UniversalStyle from './styles/Universal';

import 'normalize.css';
import 'rc-slider/assets/index.css';
import 'react-contexify/dist/ReactContexify.min.css';
import 'styles/components/contexify.scss';

const ContainerLimit = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
`;

const history = createBrowserHistory();

const raw = queryParamExists('raw');

const ModeratorPanel = theme => {
  const moderatorId = theme.checkModeratorId;
  const { moderatorErrors } = theme;
  const panelurl = `/panel-moderatora/${moderatorId}`;
  const mainurl = `/moderatorpanel/${moderatorId}`;
  const basketurl = `/koszyk-moderatora/${moderatorId}`;

  return (
    <>
      {theme.theme === 'default' && <GlobalStyle />}
      {theme.theme === 'dark' && <GlobalStyleDark />}
      <AnimationsStyle />
      <UniversalStyle />
      <Router history={history}>
        <>
          <Route
            render={({ match }) => {
              if (!/^(404)$/.test(match.params.slug) && !raw) {
                return <ModeratorTop moderatorId={moderatorId} />;
              }
              return null;
            }}
          />
          <div>
            {moderatorErrors === 0 && (
              <ContainerLimit>
                <Switch>
                  <Redirect exact from={mainurl} to={panelurl} />
                  <Route path={panelurl} component={ModeratorPage} />
                  <Route path={basketurl} component={ModeratorBasketPage} />
                </Switch>
              </ContainerLimit>
            )}
            {moderatorErrors === 1 && (
              <ContainerLimit>
                <h2>Moderator nie istnieje, skontaktuj się ze Streamerem aby otrzymać nowy link</h2>
              </ContainerLimit>
            )}
            {moderatorErrors === 2 && (
              <ContainerLimit>
                <h2>Ten link jest już nie ważny, skontaktuj się ze Streamerem po nowy</h2>
              </ContainerLimit>
            )}
          </div>
        </>
      </Router>
    </>
  );
};

export default ModeratorPanel;
