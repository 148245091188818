import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';

import { ReactComponent as PauseIcon } from 'assets/icons/pause.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as SoundOnIcon } from 'assets/icons/speaker-on.svg';
import { ReactComponent as SoundOffIcon } from 'assets/icons/speaker-off.svg';
import { ReactComponent as SkipIcon } from 'assets/icons/forward.svg';

import { toggleWidgetAlerts, toggleWidgetAlertsSounds } from 'store/actions/userData';
import { queryParamExists } from 'utils';

import {
  YellowControlButton,
  RedControlButton,
  BlueControlButton,
} from './components/ControlButton';
import OpenRawViewButton from './components/OpenRawViewButton';
import Root from './components/Root';

const AlertsControlWidget = () => {
  const dispatch = useDispatch();

  const {
    alertsDisabled,
    alertsSoundDisabled,
    working,
    patchingFragment,
    currentUserId,
  } = useSelector(state => ({
    currentUserId: state.userData.info.id,
    alertsDisabled: state.userData.info.widget_alerts_disabled,
    alertsSoundDisabled: state.userData.info.widget_alerts_sound_disabled,
    working: state.userData.isPatching,
    patchingFragment: state.userData.patchingFragment,
  }));

  const socket = useRef(null);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);

    return () => {
      socket.current.disconnect();
      socket.current = null;
    };
  }, []);

  const isMobile = document.documentElement.clientWidth <= 768;
  const isMobileOrRaw = isMobile || queryParamExists('raw');

  const handleAlertsToggle = () => {
    dispatch(toggleWidgetAlerts());
  };

  const sendCommand = (command, data = undefined) => {
    if (socket.current) {
      socket.current.emit('commands', currentUserId, {
        command,
        data,
      });
    }
  };

  const handleAlertsSoundsToggle = () => {
    sendCommand('setAlertsSoundDisabled', !alertsSoundDisabled);
    dispatch(toggleWidgetAlertsSounds());
  };

  const handleSkipMessage = () => {
    sendCommand('skipMessage');
  };

  return (
    <Root leftAlign={isMobile}>
      <BlueControlButton active icon={<SkipIcon />} onClick={handleSkipMessage}>
        Pomiń
      </BlueControlButton>
      <RedControlButton
        active={alertsSoundDisabled}
        disabled={working && patchingFragment === 'toggleAlertsSounds'}
        loading={working && patchingFragment === 'toggleAlertsSounds'}
        icon={alertsSoundDisabled ? <SoundOffIcon /> : <SoundOnIcon />}
        onClick={handleAlertsSoundsToggle}
        rounded
      />
      {isMobileOrRaw ? (
        <YellowControlButton
          active={alertsDisabled}
          disabled={working && patchingFragment === 'toggleAlerts'}
          loading={working && patchingFragment === 'toggleAlerts'}
          icon={alertsDisabled ? <PlayIcon /> : <PauseIcon />}
          onClick={handleAlertsToggle}
          rounded
        />
      ) : (
        <YellowControlButton
          active={alertsDisabled}
          disabled={working && patchingFragment === 'toggleAlerts'}
          loading={working && patchingFragment === 'toggleAlerts'}
          icon={alertsDisabled ? <PlayIcon /> : <PauseIcon />}
          onClick={handleAlertsToggle}
        >
          {alertsDisabled ? 'Wznów' : 'Wstrzymaj'}
        </YellowControlButton>
      )}
      {!isMobileOrRaw && <OpenRawViewButton />}
    </Root>
  );
};

export default AlertsControlWidget;
