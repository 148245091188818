import io from 'socket.io-client';
import store from 'store';

let instances = null;

/**
 * Funkcja uruchamiająca wszystkie sockety dla napiwków, konfiguracji oraz szablonów.
 *
 * @param {function} tipCallback
 * @param {function} moderationCallback
 * @param {function} commandsCallback
 * @returns {object}
 */
export default function sockets(
  tipCallback = null,
  moderationCallback = null,
  commandsCallback = null,
) {
  const { userData } = store.getState();
  const { tipsModeration } = store.getState();

  let uid = userData.info.id ? userData.info.id : null;
  const mid = tipsModeration.currentModerator ? tipsModeration.currentModerator.user_id : null;

  if (uid === null && mid) {
    uid = mid;
  }

  if (!instances) {
    instances = {
      tip: io(`${process.env.REACT_APP_SOCKET_URL}/tip/${uid}`),
      moderation: io(`${process.env.REACT_APP_SOCKET_URL}/moderation/${uid}`),
      commands: io(`${process.env.REACT_APP_SOCKET_URL}/commands/${uid}`),
    };
  }

  return {
    on() {
      instances.tip.on('tip', tipCallback);
      instances.moderation.on('moderation', moderationCallback);
      instances.commands.on('commands', commandsCallback);
    },
    off() {
      instances.tip.off('tip', tipCallback);
      instances.moderation.off('moderation', moderationCallback);
      instances.commands.off('commands', commandsCallback);
    },
  };
}
