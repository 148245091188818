import styled, { css } from 'styled-components';

import Button from 'components/Button';

const defaultBgColor = 'rgba(96, 96, 96, 0.25)';

const rounded = css`
  padding-left: 12px;
  padding-right: 12px;
`;

const ControlButton = styled(Button)`
  background-color: ${defaultBgColor};
  text-transform: uppercase;
  font-weight: bold;
  border-color: ${defaultBgColor};

  svg {
    height: 12px;
  }

  ${props => props.rounded && rounded}
`;

export const YellowControlButton = styled(ControlButton)`
  background-color: ${props => (props.active ? 'rgba(255, 185, 48, 0.25)' : defaultBgColor)};
  border-color: ${props => (props.active ? '#FFB930' : defaultBgColor)};
`;

export const RedControlButton = styled(ControlButton)`
  background-color: ${props => (props.active ? 'rgba(255, 89, 89, 0.25)' : defaultBgColor)};
  border-color: ${props => (props.active ? '#FF6767' : defaultBgColor)};
`;

// 037CFA to rgba(3, 124, 250, 0.25)
export const BlueControlButton = styled(ControlButton)`
  background-color: ${props => (props.active ? 'rgba(3, 124, 250, 0.25)' : defaultBgColor)};
  border-color: ${props => (props.active ? '#037CFA' : defaultBgColor)};
`;
