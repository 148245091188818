import PropTypes from 'prop-types';
import React, { Component } from 'react';
import socket from 'utils/socket';

import store from 'store';
import { fetchGoalsIfNeeded } from 'store/actions/goals';

import { queryParamExists } from 'utils';
import http from 'utils/http';

import AudioClipOn from 'assets/defaultMedia/audios/SoundOn.mp3';
import AudioClipOff from 'assets/defaultMedia/audios/SoundOff.mp3';
import { ReactComponent as Comment } from 'assets/icons/comments.svg';
import { ReactComponent as Shield } from 'assets/icons/shield.svg';
import { ReactComponent as Notification } from 'assets/icons/notification.svg';
import { ReactComponent as NotificationOff } from 'assets/icons/notification-off.svg';

import ModeratorModal from 'pages/UserPanel/components/ModeratorModal';
import ModeratorBasketModal from 'pages/UserPanel/components/ModeratorBasketModal';
import { HintUnwantedMessages } from 'components/Hints';
import ToggleButton from 'components/styled/buttons/ToggleButton';

import { Provider } from './Context';
import PaginationBar from './blocks/PaginationBar';
import TipsList from './blocks/TipsList';
import TipsModerationList from './blocks/TipsModerationList';
import Toolbar from './blocks/Toolbar';
import ModeratorBar from './blocks/ModeratorBar';
import StyledTipsList from './styles/StyledTipsList';

export default class TipsListPage extends Component {
  static propTypes = {
    fetchTipsForTipsListPage: PropTypes.instanceOf(Function).isRequired,
    resendTip: PropTypes.instanceOf(Function).isRequired,
    deleteTip: PropTypes.instanceOf(Function).isRequired,
    receivedTip: PropTypes.instanceOf(Function).isRequired,
    receivedTipModeration: PropTypes.instanceOf(Function).isRequired,
    tips: PropTypes.instanceOf(Object).isRequired,
    info: PropTypes.instanceOf(Object).isRequired,
    showGoals: PropTypes.bool.isRequired,
    fetchTipsModerationForTipsListPage: PropTypes.instanceOf(Function).isRequired,
    tipsModeration: PropTypes.instanceOf(Object).isRequired,
    approveTipModeration: PropTypes.instanceOf(Function).isRequired,
    rejectTipModeration: PropTypes.instanceOf(Function).isRequired,
    restoreTipModeration: PropTypes.instanceOf(Function).isRequired,
    page: PropTypes.number.isRequired,
    fetchTipsModerationBasketForModerators: PropTypes.instanceOf(Function).isRequired,
    tipsModerationBasket: PropTypes.instanceOf(Object).isRequired,
    displayModerationBasketModal: PropTypes.instanceOf(Function).isRequired,
    hideModerationBasketModal: PropTypes.instanceOf(Function).isRequired,
    forceModerationBasketModal: PropTypes.bool.isRequired,
    refreshTipsModerationListOnTip: PropTypes.instanceOf(Function).isRequired,
  };

  static openPopup() {
    window.open(
      `${window.location.href}?raw`,
      'open_window',
      'menubar=no, toolbar=no, location=no, directories=no, status=no, scrollbars=no, resizable=no, dependent, width=1360, height=648, left=0, top=0',
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      mute: !props.info.message_audio,
      paginationItemsCount: 10,
      page: 0,
      searchPhrase: '',
      ordering: 'default',
      lastSearch: '',
      lastOrder: 'default',
      lastPage: 0,
      lastPaginationItemsCount: 10,
      moderatorModalVisibility: false,
      tipSoundOn: new Audio(AudioClipOn),
      tipSoundOff: new Audio(AudioClipOff),
      hideUnwantedMessagesHint: !!localStorage.getItem(`hideUnwantedMessagesHint`),
    };

    this.socket = socket(this.receiveTip, this.receiveTipModeration);
  }

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const {
      fetchTipsForTipsListPage,
      fetchTipsModerationForTipsListPage,
      fetchTipsModerationBasketForModerators,
    } = this.props;
    const { paginationItemsCount, page } = this.state;

    fetchTipsForTipsListPage(paginationItemsCount, page);
    fetchTipsModerationForTipsListPage();
    fetchTipsModerationBasketForModerators();

    store.dispatch(fetchGoalsIfNeeded());

    this.socket.on();

    document.title = 'Tipply | Lista wiadomości';

    if (window.location.hash === '#moderation-modal') {
      this.showModerationModal();
    }
  }

  componentWillUnmount() {
    this.socket.off();
  }

  getFilteredTips() {
    const { ordering } = this.state;
    const { searchPhrase } = this.state;

    const { lastOrder } = this.state;
    const { lastSearch } = this.state;
    const { lastPage } = this.state;
    const { lastPaginationItemsCount } = this.state;
    let proceed = false;

    // eslint-disable-next-line no-shadow
    const { fetchTipsForTipsListPage, fetchTipsModerationForTipsListPage } = this.props;
    const { paginationItemsCount, page } = this.state;

    if (lastOrder !== ordering) {
      this.setState(prevState => ({
        lastOrder: prevState.ordering,
      }));
      proceed = true;
    }

    if (lastSearch !== searchPhrase) {
      this.setState(prevState => ({
        lastSearch: prevState.searchPhrase,
      }));
      proceed = true;
    }

    if (lastPage !== page) {
      this.setState(prevState => ({
        lastPage: prevState.page,
      }));
      proceed = true;
    }

    if (lastPaginationItemsCount !== paginationItemsCount) {
      this.setState(prevState => ({
        lastPaginationItemsCount: prevState.paginationItemsCount,
      }));
      proceed = true;
    }

    if (proceed) {
      fetchTipsForTipsListPage(
        paginationItemsCount,
        page * paginationItemsCount,
        ordering,
        searchPhrase,
      );
      fetchTipsModerationForTipsListPage(
        paginationItemsCount,
        page * paginationItemsCount,
        ordering,
        searchPhrase,
      );
    }

    const { tips } = this.props;

    return tips.filter(tip => !tip.test && !tip.resent);
  }

  getPaginatedTips() {
    return this.getFilteredTips();
  }

  setHideUnwantedMessagesHint() {
    localStorage.setItem(`hideUnwantedMessagesHint`, 'true');
    this.setState({ hideUnwantedMessagesHint: true });
  }

  receiveTip = json => {
    const { receivedTip, tipsModeration, refreshTipsModerationListOnTip } = this.props;
    const { tipSoundOn, mute } = this.state;
    const parsed = JSON.parse(json);

    const filterResult = tipsModeration.filter(item => item.payment_id !== parsed.payment_id);

    if (filterResult !== tipsModeration) {
      refreshTipsModerationListOnTip(filterResult);
    }

    if (!parsed.resent && !parsed.test) {
      receivedTip(parsed);
      this.forceUpdate();
      if (!mute) {
        tipSoundOn.play();
      }
    }
  };

  receiveTipModeration = json => {
    const { receivedTipModeration } = this.props;
    const { tipSoundOn, mute } = this.state;
    const parsed = JSON.parse(json);
    if (!parsed.resent && !parsed.test) {
      receivedTipModeration(parsed);
      this.forceUpdate();
      if (!mute) {
        tipSoundOn.play();
      }
    }
  };

  showModerationModal() {
    this.setState({ moderatorModalVisibility: true });
  }

  async toggleMute() {
    const { mute, tipSoundOn, tipSoundOff } = this.state;

    http.post('/user/toggle-message-audio').catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });

    if (mute) {
      tipSoundOn.play();
    } else {
      tipSoundOff.play();
    }

    this.setState({ mute: mute === false });
  }

  changePaginationCount(count) {
    this.setState({ paginationItemsCount: count, page: 0 });
  }

  prevPage() {
    this.setState(prevState => ({
      page: Math.max(0, prevState.page - 1),
    }));
  }

  nextPage() {
    const { tips } = this.props;
    const { page, paginationItemsCount } = this.state;

    if (tips.length === paginationItemsCount) {
      this.setState({
        page: page + 1,
      });
    }
  }

  render() {
    const {
      mute,
      paginationItemsCount,
      moderatorModalVisibility,
      hideUnwantedMessagesHint,
    } = this.state;
    const {
      resendTip,
      deleteTip,
      info,
      showGoals,
      approveTipModeration,
      rejectTipModeration,
      restoreTipModeration,
      fetchTipsModerationForTipsListPage,
      fetchTipsForTipsListPage,
      page,
      fetchTipsModerationBasketForModerators,
      displayModerationBasketModal,
      hideModerationBasketModal,
      forceModerationBasketModal,
    } = this.props;
    const moderationMode = info.moderation_mode;

    const tips = this.getPaginatedTips();
    const raw = queryParamExists('raw');

    const { tipsModeration, tipsModerationBasket } = this.props;

    return (
      <Provider
        value={{
          search: searchPhrase => this.setState({ searchPhrase }),
          filter: ordering => this.setState({ ordering }),
        }}
      >
        {raw ? (
          <div>
            {!hideUnwantedMessagesHint && (
              <HintUnwantedMessages
                discardHandler={() => {
                  this.setHideUnwantedMessagesHint();
                }}
              />
            )}
            <TipsList
              tips={tips}
              resendTip={resendTip}
              deleteTip={deleteTip}
              moderationMode={moderationMode}
              showGoals={showGoals}
            />
            <PaginationBar
              paginationItemsCount={paginationItemsCount}
              changePaginationCount={count => this.changePaginationCount(count)}
              changePaginationPagePrev={() => this.prevPage()}
              changePaginationPageNext={() => this.nextPage()}
            />
          </div>
        ) : (
          <StyledTipsList className="app-container tips">
            <div className="box tips-header">
              <div className="tips-header__intro">
                <h4>Ostatnie wiadomości</h4>
                <p className="text-light no-margin">
                  Tutaj możesz zobaczyć listę osób, które ostatnio wysyłały wiadomości na Twoje
                  konto. Lista jest odświeżana w czasie rzeczywistym więc nie martw się, że coś
                  pominiesz!
                </p>
              </div>
              <div className="tips-header__right-part">
                <div>
                  <button
                    type="button"
                    onClick={() => TipsListPage.openPopup()}
                    className="tips-header__new_window_button hover-animation-blue"
                  >
                    <Comment width={20} />
                    <span>OTWÓRZ WIADOMOŚCI W NOWYM OKNIE</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => this.showModerationModal()}
                    className="tips-header__moderator_button"
                  >
                    <Shield width={20} />
                    <span>MODERACJA WIADOMOŚCI</span>
                  </button>
                </div>
                <div>
                  <ToggleButton
                    type="button"
                    style={{ width: 172, justifyContent: 'flex-start' }}
                    off={mute}
                    onClick={() => this.toggleMute()}
                  >
                    {mute && (
                      <React.Fragment>
                        <NotificationOff />
                        {' SFX OFF'}
                      </React.Fragment>
                    )}
                    {!mute && (
                      <React.Fragment>
                        <Notification />
                        {' SFX ON'}
                      </React.Fragment>
                    )}
                  </ToggleButton>
                </div>
              </div>
            </div>
            <ModeratorBar />
            <Toolbar />
            {!hideUnwantedMessagesHint && (
              <HintUnwantedMessages
                discardHandler={() => {
                  this.setHideUnwantedMessagesHint();
                }}
              />
            )}
            <TipsModerationList
              tipsModeration={tipsModeration}
              showGoals={showGoals}
              approveTipModeration={approveTipModeration}
              rejectTipModeration={rejectTipModeration}
              fetchTipsModerationForTipsListPage={fetchTipsModerationForTipsListPage}
              fetchTipsForTipsListPage={fetchTipsForTipsListPage}
              paginationItemsCount={paginationItemsCount}
              page={page}
              fetchTipsModerationBasketForModerators={fetchTipsModerationBasketForModerators}
            />
            <TipsList
              tips={tips}
              resendTip={resendTip}
              deleteTip={deleteTip}
              moderationMode={moderationMode}
              showGoals={showGoals}
            />
            <PaginationBar
              paginationItemsCount={paginationItemsCount}
              changePaginationCount={count => this.changePaginationCount(count)}
              changePaginationPagePrev={() => this.prevPage()}
              changePaginationPageNext={() => this.nextPage()}
            />
            <ModeratorModal
              isMounted={moderatorModalVisibility}
              onClose={() => {
                this.setState({ moderatorModalVisibility: false });
              }}
              displayModerationBasketModal={displayModerationBasketModal}
            />
            <ModeratorBasketModal
              tipsModerationBasket={tipsModerationBasket}
              fetchTipsModerationBasketForModerators={fetchTipsModerationBasketForModerators}
              fetchTipsModerationForTipsListPage={fetchTipsModerationForTipsListPage}
              restoreTipModeration={restoreTipModeration}
              isMounted={forceModerationBasketModal}
              onClose={() => {
                hideModerationBasketModal();
              }}
            />
          </StyledTipsList>
        )}
      </Provider>
    );
  }
}
